//
// Config values
//

$platform-name: 'youngclassicalartiststrust';


//
// Colours
//

$_red: #f2594a;
$_blue: #37abe1;
$_black: #141412;
$_grey: #9e9eaa;
$_pink: #e458a9;
$_yellow: #fdc536;
$_darkblue: #1447cb;
$_green: #8af9a4;

$brand-primary: $_blue;
$brand-secondary: $_red;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo-width: 200px;
$logo-height: 90px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu


//
// Typography
//

$font-family-base: 'Poppins', sans-serif;

$font-weight-normal: 300;
$font-weight-bold: 500;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links
$link-hover-decoration: none;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: $font-weight-bold;
$btn-hover-style: lighten; // darken or lighten the button on hover
$btn-text-colour: white;

// Donate button
$donate-btn-colour: white;

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $_black; // A colour, or 'brand'
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific

// Footer specific social icons


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
// $card-heading-font-size: $font-size-h4;

// Card text overlay

// Card text over
$card-text-over-image-darken-image-amount: 0.4;

// Card side by side

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;


//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//
$header-background-colour: white;


// Header content
$header-content-margin-bottom: 0;

// Tagline

// Main call to action
$cta-donate-on-appeal: true; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;
$header-search-input-max-width: 150px;
$header-search-input-border-width: 0 0 1px 0; // t-r-b-l
$header-search-input-placeholder-colour: $_grey;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $_grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//
$header-sticky-enabled: true;
$header-sticky-logo-width: $logo-width * 1;
$header-sticky-logo-height: $logo-height * 1;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;


//
// Navigation
//
$nav-background-colour: white;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-colour: $_black;
$nav-top-level-item-hover-colour: darken($brand-primary, 10%);
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-item-font-size: .9em;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.1);
$nav-submenu-item-colour: $_black;
$nav-submenu-item-hover-colour: white;
$nav-submenu-item-hover-background-colour: $brand-primary;

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: right; // left, center, right


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-image-overlay: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 65%); // Add a colour value or gradient here
$carousel-details-background-colour: transparent;
$carousel-details-position-y: center; // top, center, bottom
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-summary-colour: white;
$carousel-heading-colour: white;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-font-size: 1.125em;
$carousel-details-max-width: 500px;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 1.5em;
$carousel-controls-icon-colour: white;
$carousel-controls-margin-x: $spacer;

// Carousel buttons (dots or tabs)

// Carousel buttons dots
$carousel-dots-padding: $spacer * 4;

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: transparent;
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 4;
$home-intro-font-weight: $font-weight-normal;

$home-intro-content-max-width: $post-content-width;


//
// Home features
//

$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-max-width: 100%;
$home-features-cards-entry-animation-prefab: 2; // See https://fresco-docs.netlify.com/#/cards/entry-animations


//
// Impact stats
//
$impact-stats-padding-y: $spacer * 3;


// Heading
$impact-stats-heading-margin-bottom: $spacer * 1;
$impact-stats-heading-colour: white;

// Individual stat

// Figure
$impact-stats-figure-colour: white;
$impact-stats-figure-font-size: 4em;

// Summary
$impact-stats-summary-colour: white;
$impact-stats-summary-font-weight: $font-weight-bold;


//
// Home feeds
//
$home-feeds-padding-top: $spacer * 4;
$home-feeds-padding-bottom: $spacer * 3;


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $_black;
$footer-boxes-count: 3;
$footer-prefab: 3;

// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-heading-font-size: $font-size-h5;
$newsletter-colour: white;
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-heading-text-align: left; // left, center, right
$newsletter-button-background-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: below; // adjacent, below or above - where do you want the sidebar to be positioned on posts?

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-colour: $brand-primary;
$blockquote-background-colour: transparent;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//


// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: $donate-colour;
$quick-giving-donation-amount-figure-colour: white;



//
// Home quick giving panel
//
$home-quick-giving-donation-amount-figure-colour: $quick-giving-donation-amount-figure-colour;
$home-quick-giving-donate-btn-background-colour: white;
$home-quick-giving-donate-btn-colour: $_black;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//


// Subsite logo

// Subsite nav


//
// Cookie consent
//
